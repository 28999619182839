import React, { useState } from "react";
import styled from "styled-components";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import ContextConsumer from "src/layouts/Context.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import { Link } from "gatsby";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";

import main from "src/assets/styles/main.js";

import Line from "src/components/global/Line.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Arrow from "src/assets/svg/arrow.inline.svg";
import slugify from "slugify";

import LogoLotie from "src/components/global/LogoLotie.js";
import BackButton from "src/components/global/BackButton.js";

import ContactForm from "src/components/contactForm/ContactForm.js";

const StyledLink = styled(Link)`
  line-height: 0;
  position: fixed;
  top: 45rem;
  z-index: 999;

  @media (max-width: ${mediaQuery.tablet}) {
    top: 15rem;
  }
`;

const StyledHamburger = styled.div`
  display: inline-block;
  position: relative;

  flex-shrink: 1;
  width: 20rem !important;
  height: 20rem;

  overflow: hidden;
  cursor: pointer;
  z-index: 99;
  flex-shrink: 0;

  :hover {
    .line-2:before {
      width: 20rem;

      transition: width 0.4s ease;
    }
  }

  .line-1,
  .line-2 {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    transition: transform 0.4s ease,
      background 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .line-1:before,
  .line-2:before {
    content: "";
    display: block;
    width: 20rem;
    height: 1rem;
    background: ${(props) => (props.colorBg ? props.colorBg : "var(--rest)")};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    @media (max-width: ${mediaQuery.tablet}) {
      background: ${(props) =>
        props.colorText ? props.colorText : "var(--bg)"};
    }
  }
  .line-2:before {
    width: 14rem;
    right: 0;
    left: auto;
    transition: transform 0.4s ease, width 0.4s ease;
  }

  &.open .line-1:before,
  &.open .line-2:before {
    outline: 0 solid transparent;
  }
  .line-1 {
    transform: translateY(-4rem);
  }
  .line-2 {
    transform: translateY(4rem);
  }

  &.open .line-1 {
    transform: rotate(225deg);
  }
  &.open .line-2 {
    transform: rotate(-45deg) translateY(0px);
  }

  &.open .line-2:before {
    width: 20rem;
    left: 0;
    right: 0;
    transition: width 0.4s ease;
  }
`;

const Lang = styled(Link)`
  margin-right: 20rem;
  text-decoration: none;
  position: relative;
  top: 1rem;
  font-size: ${typographySizes.s}rem;
`;

//////////////////////////////////////////////// open nav
const SwitchWrapper = styled.div`
  z-index: 99999;
  font-size: 0;
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 44rem;
    height: 19rem;
    background: var(--bg);
    display: block;
    border-radius: 100rem;
    position: relative;
    border: solid 1rem var(--rest);
  }

  label:after {
    content: "";
    position: absolute;
    top: 3rem;
    left: 3rem;
    width: 13rem;
    height: 13rem;
    background: var(--rest);
    border-radius: 90rem;
    transition: 0.3s;
  }

  input:checked + label {
    background: var(--bg);
  }

  input:checked + label:after {
    left: calc(100% - 3rem);
    transform: translateX(-100%);
    background: var(--rest);
  }

  label:active:after {
    width: 38rem;
  }
`;
const StyledArrow = styled(Arrow)`
  width: 28rem;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :first-of-type {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -40rem;
  }
  #arr {
    fill: var(--bg);
  }
`;
const OpenContainer = styled.div`
  background-color: transparent;
  border-radius: ${main.radius}rem;
  transition: background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 30rem;
  top: 30rem;
  z-index: 1000;
  overflow: hidden;
  /* height: ${(props) => (props.open ? "490rem" : "60.5rem")};
  width: ${(props) => (props.open ? "420rem" : "100rem")}; */
  /* height: 60.5rem; */
  max-height: 60.5rem;
  width: 100rem;
  /* transition: max-height 0.8s 0.1s cubic-bezier(0.65, 0, 0.35, 1),
    width 0.5s cubic-bezier(0.65, 0, 0.35, 1),
    background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1); */
  &.open {
    /* height: 470rem; */
    max-height: 700rem;
    width: 420rem;
    /* transition: max-height 0.8s 0.1s cubic-bezier(0.65, 0, 0.35, 1),
      width 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1); */
    background-color: ${(props) =>
      props.colorBg ? props.colorBg : "var(--rest)"};
    ${Lang} {
      color: ${(props) =>
        props.colorText ? props.colorText : "var(--bg)"} !important;
    }
    .line-1:before,
    .line-2:before {
      background: ${(props) =>
        props.colorText ? props.colorText : "var(--bg)"};
    }
  }

  a,
  h3,
  p,
  span {
    color: ${(props) =>
      props.colorText ? props.colorText : "var(--bg)"} !important;
  }
  hr {
    border-top: 1rem solid
      ${(props) => (props.colorText ? props.colorText : "var(--bg)")} !important;
  }
  ${StyledArrow} {
    #arr {
      fill: ${(props) => (props.colorText ? props.colorText : "var(--bg)")};
    }
  }
  ${Lang} {
    color: ${(props) =>
      props.colorBg ? props.colorBg : "var(--rest)"} !important;
    @media (max-width: ${mediaQuery.tablet}) {
      color: ${(props) =>
        props.colorText ? props.colorText : "var(--bg)"} !important;
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    background-color: ${(props) =>
      props.colorBg ? props.colorBg : "var(--rest)"};
    transition: max-height 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      width 0.3s 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    right: ${distances.pageMarginSmall}rem;
    top: initial;
    bottom: ${distances.pageMarginSmall}rem;
    max-height: 50.5rem;
    width: calc(92rem);
    &.open {
      /* transition: height 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1),
        width 0.3s cubic-bezier(0.65, 0, 0.35, 1); */
      width: calc(100% - ${distances.pageMarginSmall * 2}rem);
    }
  }
`;
const StyledMainWrapper = styled(MainWrapper)`
  height: 100rem;
  z-index: 999;
  position: fixed;
  background-color: transparent;

  :hover {
    .line-1:before,
    .line-2:before {
      background: ${(props) =>
        props.colorText ? props.colorText : "var(--bg)"};
      transition: transform 0.4s ease,
        background 0.5s cubic-bezier(0.65, 0, 0.35, 1), width 0.4s ease;
    }
    ${Lang} {
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      color: ${(props) =>
        props.colorText ? props.colorText : "var(--bg)"} !important;
    }
    ${OpenContainer} {
      background-color: ${(props) =>
        props.colorBg ? props.colorBg : "var(--rest)"};

      transition: max-height 0.5s 0s cubic-bezier(0.65, 0, 0.35, 1),
        width 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1),
        background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1);

      &.open {
        transition: max-height 0.8s 0.1s cubic-bezier(0.65, 0, 0.35, 1),
          width 0.5s cubic-bezier(0.65, 0, 0.35, 1),
          background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
`;
const OpenNavWrapper = styled.div`
  margin-top: 0;
  /* position: absolute; */
  right: 0;
  /* display: flex; */
  padding: 20rem;
  /* flex-direction: column;
  align-items: flex-end; */

  width: 380rem;
  @media (max-width: ${mediaQuery.tablet}) {
    bottom: 0;
    width: calc(100vw - ${distances.pageMarginSmall * 4}rem);
    display: flex;
    /* height: calc(450rem - ${distances.pageMarginSmall * 2}rem); */
    padding: ${distances.pageMarginSmall}rem;
    flex-direction: column-reverse;
  }
`;

const OpenCol = styled.div`
  width: 100%;
  margin-top: 70rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  width: 380rem;

  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    align-items: flex-end;
    overflow: initial;
    width: 100%;
  }
`;
const TopRightWrapper = styled.div`
  position: relative;

  /* z-index: 999; */
  display: flex;
`;
const LinksWrapper = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: ${mediaQuery.desktop}) {
    /* width: calc(56.25% - 20rem);
    padding-right: 20rem; */
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding-right: 0rem;
    margin-bottom: 45rem;
  }
`;

const LinkInner = styled.div`
  padding: 20rem 0;
  font-size: ${typographySizes.sm}rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  span {
    font-size: inherit;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const LinkWrapper = styled(Link)`
  margin-top: -1rem;
  display: block;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  &:first-of-type {
    margin-top: 0;
  }
  span {
    color: var(--bg);
    &::first-letter {
      text-transform: uppercase;
    }
  }

  :hover {
    ${StyledArrow} {
      transform: translateX(28rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      &:first-of-type {
        transform: translate(40rem, -50%);
      }
    }
    span {
      transform: translateX(50rem);
      transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;
const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 10rem; */
  margin-bottom: 0rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
    margin-bottom: 10rem;
  }
`;

const SocialLink = styled.a`
  font-size: ${typographySizes.s}rem;
  line-height: ${typographySizes.sh};
  display: inline-block;
  text-decoration: none;
  color: var(--bg);
  margin-right: 20rem;
  margin-top: 20rem;
  :last-of-type {
    margin-right: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 50%;
    margin-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 5rem;
  }
`;
const NavLink = ({ name, link, onClick }) => (
  <LinkWrapper to={`/${link}`} onClick={onClick}>
    <Line color={"var(--bg)"} />
    <LinkInner>
      <StyledArrow />
      <span> {name}</span>
      <StyledArrow />
    </LinkInner>
    <Line color={"var(--bg)"} />
  </LinkWrapper>
);
const Nav = ({
  contactLink,
  aboutPage,
  worksPage,
  newsPage,
  contactPage,
  langPageName,
  shopPage,
  lang,
  catalog,
  colorBg,
  colorText,
  home,
  backBtnUrl,
  hideCta,
}) => {
  const [navOpen, setNavOpen] = useState(false);
  const aboutLink = slugify(aboutPage, {
    lower: true,
    strict: true,
  });
  const workLink = slugify(worksPage, {
    lower: true,
    strict: true,
  });
  const newsLink = slugify(newsPage, {
    lower: true,
    strict: true,
  });
  const contactLinkLink = slugify(contactPage, {
    lower: true,
    strict: true,
  });
  const slugLangPageName = slugify(langPageName, {
    lower: true,
    strict: true,
  });
  const shopLink = slugify(shopPage, {
    lower: true,
    strict: true,
  });
  return (
    <>
      <ContactForm
        hide={hideCta}
        lang={lang}
        colorText={colorText}
        colorBg={colorBg}
      />
      {backBtnUrl && (
        <BackButton
          lang={lang}
          colorText={colorBg}
          previousPageLink={backBtnUrl}
        />
      )}

      <StyledMainWrapper colorText={colorText} colorBg={colorBg}>
        <ContextConsumer>
          {({ data, set }) => (
            <>
              <StyledLink
                onClick={() => set({ navOpen: false })}
                to={!home && `${lang === "en" ? `/en/home` : `/`}`}
              >
                <LogoLotie colorText={colorBg} />
              </StyledLink>

              <div>
                <OpenContainer
                  colorBg={colorBg}
                  colorText={colorText}
                  // open={data.navOpen}
                  className={data.navOpen && "open"}
                >
                  <OpenNavWrapper>
                    <TopRow>
                      <ThemeToggler>
                        {({ theme, toggleTheme }) => (
                          <SwitchWrapper>
                            <input
                              type="checkbox"
                              id="switchNAv"
                              onChange={(e) => {
                                toggleTheme(
                                  !e.target.checked ? "dark" : "white"
                                );
                                set({ themeBlack: !data.themeBlack });
                              }}
                              checked={!data.themeBlack}
                            />
                            <label for="switchNAv">Toggle</label>
                          </SwitchWrapper>
                        )}
                      </ThemeToggler>
                      <TopRightWrapper>
                        <Lang
                          colorText={colorText}
                          colorBg={colorBg}
                          className={navOpen && "open"}
                          onClick={() => setNavOpen(false)}
                          to={`${
                            lang === "en"
                              ? `/${catalog ? catalog : ""}${slugLangPageName}`
                              : `/en/${
                                  catalog ? catalog : ""
                                }${slugLangPageName}`
                          }`}
                        >
                          {lang === "en" ? "PL" : "EN"}
                        </Lang>

                        <StyledHamburger
                          colorText={colorText}
                          colorBg={colorBg}
                          // onClick={() => setNavOpen(!navOpen)}
                          onClick={() => set({ navOpen: !data.navOpen })}
                          className={data.navOpen ? "in open" : "in"}
                          aria-label="menu button"
                        >
                          <span className="line-1"></span>
                          <span className="line-2"></span>
                        </StyledHamburger>
                      </TopRightWrapper>
                    </TopRow>

                    <OpenCol>
                      <LinksWrapper>
                        <NavLink
                          onClick={() => set({ navOpen: false })}
                          name={aboutPage}
                          link={lang === "en" ? `en/${aboutLink}` : aboutLink}
                        />
                        <NavLink
                          onClick={() => set({ navOpen: false })}
                          name={worksPage}
                          link={lang === "en" ? `en/${workLink}` : workLink}
                        />
                        <NavLink
                          onClick={() => set({ navOpen: false })}
                          name={newsPage}
                          link={lang === "en" ? `en/${newsLink}` : newsLink}
                        />
                        <NavLink
                          onClick={() => set({ navOpen: false })}
                          name={contactPage}
                          link={
                            lang === "en"
                              ? `en/${contactLinkLink}`
                              : contactLinkLink
                          }
                        />
                        <NavLink
                          onClick={() => set({ navOpen: false })}
                          name={shopPage}
                          link={lang === "en" ? `en/${shopLink}` : shopLink}
                        />
                      </LinksWrapper>
                      <SocialWrapper>
                        {contactLink.Social_link.map((LinkInfo) => (
                          <SocialLink href={LinkInfo.Link} target="_blank">
                            {LinkInfo.Name}
                          </SocialLink>
                        ))}
                      </SocialWrapper>
                    </OpenCol>
                  </OpenNavWrapper>
                </OpenContainer>
              </div>
            </>
          )}
        </ContextConsumer>
      </StyledMainWrapper>
    </>
  );
};

export default Nav;
